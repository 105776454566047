export const SUCCESS = 'success';
export const ERROR = 'error';
export const INVALID_EMAIL = 'INVALID_EMAIL';
export const INVALID_PASSWORD = 'INVALID_PASSWORD';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const INVALID_REQUEST = 'INVALID_REQUEST';
export const INVALID_REQUEST_ID = 'INVALID_REQUEST_ID';
export const TOKEN_EXPIRED = 'TOKEN_EXPIRED';
export const PARSE_ERROR = 'PARSE_ERROR';
export const INVALID_TARGET_URL = 'INVALID_TARGET_URL';
export const INVALID_USER_NAME = 'INVALID_USER_NAME';

export const MIN_STRING_LENGTH = 1;
export const MIN_PASSWORD_LENGTH = 8;
export const MAX_STRING_LENGTH = 100;
export const MAX_PASSWORD_LENGTH = 64;
export const MAX_NAME_LENGTH = 30;

export const VALIDATION_ERROR = 'Validation error';
export const RECAPTCHA_FAILED = 'recaptchaFailed';
export const ACCOUNT_UNCONFIRMED = 'ACCOUNT_UNCONFIRMED';
export const ACCOUNT_ALREADY_CONFIRMED = 'ACCOUNT_ALREADY_CONFIRMED';
export const USER_BLOCKED = 'USER_BLOCKED';
export const USER_NOT_FOUND = 'USER_NOT_FOUND';
export const USER_NOT_CONFIRMED = 'USER_NOT_CONFIRMED';
export const USER_EXISTS = 'USER_EXISTS';
export const USER_NAME_EMPTY = 'USER_NAME_EMPTY';
export const INVALID_SOCIAL_ACCESS_TOKEN = 'INVALID_SOCIAL_ACCESS_TOKEN';
export const ACTIVATION_LINK_INVALID = 'ACTIVATION_LINK_INVALID';
export const ACTIVATION_CODE_EXPIRED = 'ACTIVATION_CODE_EXPIRED';
export const RETRY_COUNT_EXCEEDED = 'RETRY_COUNT_EXCEEDED';
export const INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN';

export const INSECURE_PASSWORD = 'INSECURE_PASSWORD';
export const PASSWORD_EMPTY = 'PASSWORD_EMPTY';
export const INVALID_USER_ACCOUNT = 'INVALID_USER_ACCOUNT';
export const RESET_PASSWORD_TOKEN_EXPIRED = 'RESET_PASSWORD_TOKEN_EXPIRED';
export const WRONG_USERNAME_PASSWORD = 'WRONG_USERNAME_PASSWORD';
export const FORCE_PASSWORD_RESET = 'FORCE_PASSWORD_RESET';

export const VERIFICATION_REQUIRED = 'VERIFICATION_REQUIRED';
export const TWO_FACTOR_AUTH_NEEDED = 'TWO_FACTOR_AUTH_NEEDED';
export const TWO_FACTOR_AUTH_NEEDED_BUT_NOT_SUPPORTED = 'TWO_FACTOR_AUTH_NEEDED_BUT_NOT_SUPPORTED';
export const TWO_FACTOR_AUTH_SETUP_REQUIRED = 'TWO_FACTOR_AUTH_SETUP_REQUIRED';
export const VERIFICATION_NOT_FOUND = 'VERIFICATION_NOT_FOUND';
export const VERIFICATION_REQUESTS_BLOCKED = 'VERIFICATION_REQUESTS_BLOCKED';
export const VERIFICATION_PROVIDER_UNAVAILABLE = 'VERIFICATION_PROVIDER_UNAVAILABLE';
export const VERIFICATION_LIMIT_REACHED = 'VERIFICATION_LIMIT_REACHED';
export const VERIFICATION_FAILED = 'VERIFICATION_FAILED';
export const VERIFICATION_CODE_BLOCKED = 'VERIFICATION_CODE_BLOCKED';
export const CREDENTIALS_MUST_BE_UPDATED = 'CREDENTIALS_MUST_BE_UPDATED';
export const PHONE_NUMBER_LINKED_TO_TOO_MANY_ACCOUNTS = 'PHONE_NUMBER_LINKED_TO_TOO_MANY_ACCOUNTS';
export const INVALID_PHONE_NUMBER = 'INVALID_PHONE_NUMBER';
export const NOT_MOBILE = 'NOT_MOBILE';
export const FRAUDULENT_USER = 'FRAUDULENT_USER';
export const WRONG_USERNAME_PASSWORD_TMX = 'WRONG_USERNAME_PASSWORD_TMX';
export const CONFIRMATION_REQUIRED = 'CONFIRMATION_REQUIRED';
export const CAS_USER_NOT_PERMITTED = 'CAS_USER_NOT_PERMITTED';
export const API_CAR_DEALER_USER_NOT_PERMITTED = 'API_CAR_DEALER_USER_NOT_PERMITTED';
export const TOO_SOON_SINCE_LAST_EMAIL_CHANGE = 'TOO_SOON_SINCE_LAST_EMAIL_CHANGE';
export const AUTHENTICATION_FAILED = 'AUTHENTICATION_FAILED';

export const REDIRECT_TIMEOUT = 3000;

export const URL_PATHS_NOT_ALLOWED_FOR_REDIRECT = ['/buyside/external/url/', '/externalUrl/redirect/'];

export const NETWORK_INVALID = 'NETWORK_INVALID';
export const FACEBOOK = 'FACEBOOK';
export const GOOGLE = 'GOOGLE';

export const ProfilingSource = {
  RegistrationForm: 'RegistrationForm',
  SocialRegistration: 'SocialRegistration',
  LoginForm: 'LoginForm',
  SocialLogin: 'SocialLogin',
  LoginFormModal: 'LoginFormModal',
  SocialLoginModal: 'SocialLoginModal',
};

export const ProfilingFlow = {
  Registration: 'Registration',
  SuccessfulLogin: 'SuccessfulLogin',
  FailedLogin: 'FailedLogin',
};

export const TMX_CREATE_ACCOUNT_PAGE_ID = 11;

export const WHITELISTED_HOSTS = [
  'marktplaats.nl',
  'www.marktplaats.nl',
  '2dehands.be',
  'www.2dehands.be',
  '2ememain.be',
  'www.2ememain.be',
  'demo.qa-mp.so',
  'www.demo.qa-mp.so',
  'demo-2dehands.qa-mp.so',
  'www.demo-2dehands.qa-mp.so',
  'demo-2ememain.qa-mp.so',
  'www.demo-2ememain.qa-mp.so',
  'help.marktplaats.nl',
  'www.help.marktplaats.nl',
  'admarkt.marktplaats.nl',
  'www.admarkt.marktplaats.nl',
  'help.2dehands.be',
  'www.help.2dehands.be',
  'aide.2ememain.be',
  'www.aide.2ememain.be',
  'marktplaatszakelijk.nl',
  'www.marktplaatszakelijk.nl',
  '2ememainprofessionnel.be',
  'www.2ememainprofessionnel.be',
  'admarkt.2dehands.be',
  'www.admarkt.2dehands.be',
  '2dehandszakelijk.be',
  'www.2dehandszakelijk.be',
  'aurora.marktplaats.test',
  'aurora.2dehands.test',
  'aurora.2ememain.test',
  'admarkt.demo.qa-mp.so',
  'admarkt.demo-2dehands.qa-mp.so',
];

export const GA_EVENTS = {
  LoginBegin: 'LoginBegin',
  LoginAttempt: 'LoginAttempt',
  LoginSuccess: 'LoginSuccess',
  LoginFail: 'LoginFail',
  LoginFailWrongUsernamePassword: 'LoginFailWrongUsernamePassword',
  SocialLoginBegin: 'SocialLoginBegin',
  SocialLoginSuccess: 'SocialLoginSuccess',
  SocialLoginFail: 'SocialLoginFail',
  UserRegistrationBegin: 'UserRegistrationBegin',
  UserRegistrationSuccess: 'UserRegistrationSuccess',
  UserRegistrationFail: 'UserRegistrationFail',
  UserRegistrationCancel: 'UserRegistrationCancel',
  SocialUserRegistrationBegin: 'SocialUserRegistrationBegin',
  SocialUserRegistrationSuccess: 'SocialUserRegistrationSuccess',
  SocialUserRegistrationFail: 'SocialUserRegistrationFail',
  TwoFAWallShown: 'TwoFAWallShown',
  TwoFANewBegin: 'TwoFANewBegin',
  TwoFACodeEntered: 'TwoFACodeEntered',
  TwoFANewCodeRequest: 'TwoFANewCodeRequest',
  TwoFANewSuccess: 'TwoFANewSuccess',
  TwoFAErrors: 'TwoFAErrors',
  ChangeEmailInitiate: 'ChangeEmailInitiate',
  ChangeEmailConfirm: 'ChangeEmailConfirm',
  ChangeEmailUndo: 'ChangeEmailUndo',
};

export const FLOW = {
  LOGIN: 'login',
  REGISTER: 'register',
  SOCIAL_LOGIN: 'social_login',
  SOCIAL_CREATE_ACCOUNT: 'social_create_account',
  CONFIRM_IDENTITY: 'confirm_identity',
};
