import { useState, useEffect, useRef } from 'react';
import loadScript from '../utils/loadScript';

type TUseGoogleLoginProps = {
  googleSdkUrl: string;
  googleClientID: string;
  onSuccess: Function;
  onError?: Function;
};

type TUseGoogleLogin = { handleGoogleLogin: any };

const useGoogleLogin = ({ googleSdkUrl, googleClientID, onSuccess }: TUseGoogleLoginProps): TUseGoogleLogin => {
  const [isGoogleSdkLoaded, setGoogleSdkLoaded] = useState(false);
  const googleButtonRef = useRef<{ click: () => void } | null>(null);

  useEffect(() => {
    const loadGoogleScript = async () => {
      try {
        await loadScript({ url: googleSdkUrl, id: 'googleSdkScript' });
        setGoogleSdkLoaded(true);
      } catch (e) {
        setGoogleSdkLoaded(false);
      }
    };
    loadGoogleScript();
  }, [googleSdkUrl]);

  useEffect(() => {
    if (!isGoogleSdkLoaded) return;
    google.accounts.id.initialize({
      // eslint-disable-next-line
      client_id: googleClientID,
      callback: (response: any) => onSuccess(response.credential),
    });

    const createFakeGoogleWrapper = () => {
      const googleLoginWrapper: HTMLDivElement = document.createElement('div');
      googleLoginWrapper.style.display = 'none';
      googleLoginWrapper.classList.add('custom-google-button');
      document.body.appendChild(googleLoginWrapper);

      google.accounts.id.renderButton(googleLoginWrapper, {
        theme: 'filled_blue',
        size: 'medium',
        text: 'continue_with',
        // eslint-disable-next-line
        use_fedcm_for_prompt: true,
      });

      google.accounts.id.prompt();

      const googleLoginWrapperButton: HTMLDivElement = googleLoginWrapper.querySelector(
        'div[role=button]',
      ) as HTMLDivElement;

      return {
        click: () => {
          googleLoginWrapperButton.click();
        },
      };
    };
    googleButtonRef.current = createFakeGoogleWrapper();
  }, [isGoogleSdkLoaded, googleClientID]);

  const handleGoogleLogin = () => {
    googleButtonRef.current?.click();
  };

  return { handleGoogleLogin };
};

export default useGoogleLogin;
